interface siteI {
  USER_SITE_ID: string,
  USER_SITE_ROL: string,
  USER_SITE_URL: string,
}

export const haveRequireRol = (sites: siteI[] = []) => {
  if (sites.length === 0) return false;

  const currentSite = window.location.origin;
  const site = sites.find(
    site => site.USER_SITE_URL === currentSite
  );

  return site;
}