import AppRouter from "./Router/AppRouter";
import { ThemeProvider } from "@material-ui/core";
import theme from "./theme";
import './interceptor';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={ theme }>
      <AppRouter/>
    </ThemeProvider>
  )
}

export default App;
