import axios from "axios"
import { API_BASE_URL } from "../config/config";
const URL = `${API_BASE_URL}/sites`;

export interface SiteI {
  SITE_ID: string,
  SITE_NAME: string,
  SITE_URL: string,
}

interface siteSchema {
  site: string;
  url: string;
}

export const getSites = async () => {
  try {
    const response = await axios({
      url: `${URL}/sites`,
      method: 'GET',
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}

export const addSite = async (site: siteSchema) => {
  try {
    const response = await axios({
      url: `${URL}/add-site`,
      method: 'POST',
      data: site
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}

export const updateSite = async (siteId: string, site: siteSchema) => {
  try {
    const response = await axios({
      url: `${URL}/update-site/${siteId}`,
      method: 'PUT',
      data: site
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}

export const deleteSite = async (siteId: string) => {
  try {
    const response = await axios({
      url: `${URL}/delete-site/${siteId}`,
      method: 'DELETE',
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}