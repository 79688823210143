const isProductionMode = process.env.NODE_ENV === 'production';
const domain = isProductionMode ? '.cadem.cl' : 'localhost';

export function expireCookie(name:string, path: string): void {
  if(getCookie(name)) {
    document.cookie = name + "=" +
      ((path) ? ";path="+path:"")+
      ((domain)?";domain="+domain:"") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}

function getCookie(name:string): boolean {
  return document.cookie.split(';').some(c => {
    return c.trim().startsWith(`${ name }=`);
  })
}