import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import queryString from 'query-string';
import * as yup from 'yup';

import Alert from '@material-ui/lab/Alert';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LinkMaterial from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import logo from '../../../assets/logos/cadem-online.svg';

import ResetPasswrdModal from './ResetPasswordModal';
import { useLoginStyles } from './makeStyless/useLoginStyles';
import { loginParamsI, logInService } from '../../../services/loginService';

// pruebas con cookies.
import { useCookies } from 'react-cookie';
import { cookiesDomain } from '../../../config/config';
import { haveRequireRol } from '../../../helpers/haveRequireRol';
import { validateToken } from '../../../services/usersService';

const LoginScreen: React.FC<{}> = () => {
  const history = useHistory();
  const classes = useLoginStyles();
  const [, setCookie] = useCookies(['token', 'rol']);
  // obtener parametros de la url
  const locationUrl = useLocation();
  const { site = '' } = queryString.parse(locationUrl.search);
  const [showModalResetPassword, setShowModalResetPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [isAdminSite, setIsAdminSite] = useState<boolean>(false);

  const initialValues: loginParamsI = {
    email: '',
    password: '',
  };

  const checkIsAdminSite = (sites: any = []) => {
    if(haveRequireRol(sites)){
      setIsAdminSite(true);
    }else{
      setIsAdminSite(false);
    }
  }

  const validationSchema = yup.object({
    email: yup
      .string()
      .email('Formato de correo invalido.')
      .required('Correo electronico requerido.'),
    password: yup
      .string()
      .min(3, 'Su contraseña debe tener 8 caracteres como minimo')
      .max(20, 'Su contraseña no debe tener más de 20 caracteres.')
      .required('Contraseña requerida')
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      const urlSite = site?.toString() || '' ;
      const data = await logInService(values, urlSite);

      if (data.ok) {
        setIsLoading(false);
        setCookie('token', data.jwt, { domain: cookiesDomain });
        setCookie('rol', data.currentUserRol, { domain: cookiesDomain });

        // redirecciona al sitio de destino.
        if(data.toRedirect){
          window.location.href = data.toRedirect;
          return;
        }

        checkIsAdminSite(data.sites);
        setSuccess('Inicio de sesión correcto, ya puede ingresar a los demás sitios.');
      }else{
        setIsLoading(false);
        setSuccess('');
        setError(data.error);
      }

    }
  });

  useEffect(() => {
    validateToken()
      .then(resp => {
        if(resp.ok){
          checkIsAdminSite(resp.user.user.roles)
        }
      });
  }, [ isAdminSite ])

  return (
    <Container component="main" maxWidth="xs" className={classes.main}>

      <div className={classes.imageContent}>
        <img
          src={logo}
          alt="Cadem online"
          className={classes.logo}
        />
      </div>

      <CssBaseline />
      <Card>
        { isLoading && <LinearProgress />}
        <CardContent>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Inicio de sesión (SSO Cadem)
            </Typography>

            {error && (
              <Alert severity="error">
                {error}
              </Alert>
            )}

            {success && (
              <Alert severity="success">
                {success}
              </Alert>
            )}

            <form className={classes.form} onSubmit={formik.handleSubmit}>
              <TextField
                autoFocus
                required
                fullWidth
                name="email"
                margin="normal"
                variant="outlined"
                label="Correo electronico"
                autoComplete="email"
                disabled={isLoading}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                required
                fullWidth
                variant="outlined"
                margin="normal"
                name="password"
                label="Contraseña"
                type="password"
                autoComplete="current-password"
                disabled={isLoading}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isLoading}
                className={classes.submit}
              >
                Iniciar Sesión
              </Button>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  {isAdminSite && (
                    <Grid item xs>
                      <Link
                        to="/sites"
                        style={{ textDecoration: 'none', textAlign:'center' }}
                      >
                        Gestionar sitios
                      </Link>
                    </Grid>
                  )}
                  <Grid item xs>
                    <LinkMaterial
                      component="button"
                      onClick={() => setShowModalResetPassword(true)}
                    >
                      Recuperar contraseña
                    </LinkMaterial>
                  </Grid>
                </Grid>
            </form>
          </div>
        </CardContent>
      </Card>

      <ResetPasswrdModal
        open={showModalResetPassword}
        setOpen={setShowModalResetPassword}
      />
    </Container>
  );
};

export default LoginScreen;
