import { useState } from 'react';
// components.
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
// validacion de formulario.
import { Formik } from "formik";
import * as yup from "yup";
import Swal from 'sweetalert2';
import { addSite, SiteI, updateSite } from '../../../services/sitesService';

type Props = {
  open: boolean;
  setOpen: Function;
  site: SiteI,
  editMode: boolean;
  addSiteToList: Function,
  editSiteToList: Function,
};

export const SiteModal: React.FC<Props> = ({
  open,
  setOpen,
  site,
  editMode = false,
  addSiteToList,
  editSiteToList,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const handleClose = () => {
    if (!isLoading) {
      setError('');
      setOpen(false);
    }
  };

  const formValues = {
    site: !editMode ? "" : site.SITE_NAME,
    url: !editMode ? "" : site.SITE_URL,
  };

  const validationSchema = yup.object({
    site: yup
      .string()
      .min(2, "Se requiren dos caracteres minimo")
      .max(255, "Se requiren menos de 255 caracteres")
      .required("Sitio es requerido."),
    url: yup
      .string()
      .min(2, "Se requiren dos caracteres minimo")
      .max(255, "Se requiren menos de 255 caracteres")
      .required("URL es requerida."),
  });

  return (
    <Dialog
      open={open}
      fullWidth={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Formik
        initialValues={formValues}
        validationSchema={validationSchema}
        onSubmit={async ( values ) => {
          setIsLoading(true);

          if(!editMode){
            const data = await addSite(values);
            if(data.ok){
              setError('');
              setIsLoading(false);
              Swal.fire('', data.message, 'success');
              addSiteToList(data.site);
              handleClose();
            }else{
              setError(data.error.message);
              setIsLoading(false);
            }
          }else{
            const data = await updateSite(site.SITE_ID, values);
            if(data.ok){
              setError('');
              setIsLoading(false);
              Swal.fire('', data.message, 'success');
              editSiteToList(data.site);
              handleClose();
            }else{
              setError(data.error.message);
              setIsLoading(false);
            }
          }

        }}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (
          <form onSubmit={handleSubmit}>
            { isLoading && <LinearProgress />}
            <DialogTitle id="alert-dialog-title">
              { !editMode ? 'Nuevo sitio.' : 'Actualizar sitio.' }
            </DialogTitle>
            <DialogContent>
              { error && (
                <Alert severity="error">{ error }</Alert>
              )}
              <TextField
                autoFocus
                fullWidth
                name="site"
                type="text"
                margin="dense"
                label="Nombre de sitio"
                placeholder="Ingrese un nombre de sitio"
                value={values.site}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.site && Boolean(errors.site)}
                helperText={touched.site && errors.site}
              />
              <TextField
                fullWidth
                name="url"
                type="text"
                margin="dense"
                label="Url de sitio"
                placeholder="Ingrese url de sitio"
                value={values.url}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.url && Boolean(errors.url)}
                helperText={touched.url && errors.url}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="primary"
                disabled={isLoading}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={isLoading}
              >
                { !editMode ? 'Añadir sitio' : 'Actualizar sitio' }
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default SiteModal;
