import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import LoginScreen from '../Components/Pages/Login/LoginScreen';
import ResetPasswordScreen from '../Components/Pages/Login/ResetPasswordScreen';
import LogoutScreen from '../Components/Pages/Logout/LogoutScreen';
import SitesMainScreen from '../Components/Pages/Sites/SitesMainScreen';

const AppRouter: React.FC<{}> = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={ LoginScreen } />
        <Route exact path="/logout" component={ LogoutScreen } />
        <Route exact path="/sites" component={ SitesMainScreen } />
        <Route exact path="/reset-password/:token" component={ ResetPasswordScreen } />
        <Redirect to="/login" />
      </Switch>
    </Router>
  )
};

export default AppRouter;
