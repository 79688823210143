import { useHistory } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Typography from '@material-ui/core/Typography';

const NavBarComponent: React.FC<{}> = () => {
  const history = useHistory();

  const handleRedirect = () => {
    localStorage.clear();
    return history.replace('/login');
  }

  return (
    <AppBar elevation={0}>
      <Toolbar>
        <Typography variant="h6" component="h6">
          Sitios.
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          style={{ color: '#fff' }}
          onClick={handleRedirect}
        >
          <ExitToAppIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default NavBarComponent;
