import axios from "axios"
import { API_BASE_URL } from "../config/config";
const URL = `${API_BASE_URL}`;

export interface UserFromFsI {
  id: string
  CREATED_AT: Date
  USER_EMAIL: string
  USER_LASTNAME_M: string
  USER_LASTNAME_P: string
  USER_NAME: string
  USER_PASSWORD: string
  USER_ROL_ID: string
}

export interface UserI {
  userName: string,
  userLastNameM: string,
  userLastNameP: string,
  userEmail: string,
  userPassword: string,
  userRole: string,
}

export const resetPassword = async (email: string) => {
  try {
    const response = await axios({
      url: `${URL}/reset-password`,
      method: 'PUT',
      data: { email }
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}

export const sendPasswordToUpdate = async (password: string) => {
  try {
    const response = await axios({
      url: `${URL}/update-password-user`,
      method: 'PUT',
      data: { password }
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}

export const validateToken = async () => {
  try {
    const response = await axios({
      url: `${URL}/check-credentials`,
      method: 'GET',
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}
