import axios from "axios"
import { API_BASE_URL } from "../config/config";

export interface loginParamsI {
  email: string;
  password: string;
}

export const logInAdminService = async (params: loginParamsI) => {
  try {
    const response = await axios({
      url: `${API_BASE_URL}/user/login-admin`,
      method: 'POST',
      data: { ...params }
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}

export const logInService = async (params: loginParamsI, siteToLogin: string) => {
  try {
    const response = await axios({
      url: `${API_BASE_URL}/user/login`,
      method: 'POST',
      data: { ...params, siteToLogin }
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}

export const checkLocalToken = async () => {
  try {
    const response = await axios({
      url: `${API_BASE_URL}/user/check-credentials`,
      method: 'GET',
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}
