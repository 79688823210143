import { useState } from 'react';
// components.
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
// validacion de formulario.
import { Formik } from "formik";
import * as yup from "yup";
import { resetPassword } from '../../../services/usersService';
import Swal from 'sweetalert2';

type Props = {
  open: boolean;
  setOpen: Function;
};

export const ResetPasswrdModal: React.FC<Props> = ({ open, setOpen }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const handleClose = () => {
    if (!isLoading) {
      setError('');
      setOpen(false);
    }
  };

  const formValues = {
    email: "",
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .min(2, "Se requiren dos caracteres minimo")
      .max(255, "Se requiren menos de 255 caracteres")
      .email('Formato de correo electronico no valido')
      .required("Correo electronico requerido."),
  });

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Formik
        initialValues={formValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          setIsLoading(true);
          const data = await resetPassword(values.email);
          if(data.ok){
            setIsLoading(false);
            setError('');
            resetForm();
            Swal.fire('', data.message, 'success');
            handleClose();
          }else{
            setError(data.error.message);
            setIsLoading(false);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off" >
            { isLoading && <LinearProgress />}
            <DialogTitle id="alert-dialog-title">
              ¿Olvidaste tu contraseña?
            </DialogTitle>
            <DialogContent>
              { error && <Alert severity="error">{ error }</Alert>}
              <DialogContentText id="alert-dialog-description">
                Ingrese su dirección de correo electrónico y
                enviaremos un enlace de recuperación a tu bandeja de entrada.
              </DialogContentText>
              <TextField
                autoFocus
                fullWidth
                name="email"
                type="email"
                margin="dense"
                label="Correo electronico"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="primary"
                disabled={isLoading}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={isLoading}
              >
                Actualizar contraseña
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ResetPasswrdModal;
