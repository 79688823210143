import { makeStyles } from '@material-ui/core';

export const useLoginStyles = makeStyles((theme) => ({
  main: {
    // paddingTop: theme.spacing(8)
    display: 'grid',
    justifyContent: 'center',
    alignContent: 'center',
    minHeight: '100vh'
  },
  imageContent: {
    display: 'grid',
    justifyContent: 'center',
    alignContent: 'center',
    padding: '10px'
  },
  logo: {
    textAlign: 'center',
    width: 'calc(100% - 50%)',
    height: 'auto',
    margin: 'auto'
  },
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));
