import { forwardRef } from 'react'
import MaterialTable from "material-table";
// icons
import { Icons } from "material-table";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const tableIcons: Icons = {
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <KeyboardArrowRightIcon {...props} ref={ref} />),
};

const localization = {
  pagination: {
    labelDisplayedRows: "{from}-{to} de {count}",
    labelRowsSelect: "filas",
  },
  toolbar: {
    searchPlaceholder: "Buscar",
    exportCSVName: "Exportar como CSV",
  },
  header: {
    actions: "",
  },
  body: {
    emptyDataSourceMessage: "No hay registros",
    filterRow: {
        filterTooltip: "Filtro",
    },
  },
}

const options = {
  rowStyle: {
    fontSize: 15,
    fontFamily: "Roboto"
  },
  pageSize: 5,
  // pageSizeOptions: [20, 50, 100],
  exportAllData: false,
  exportButton: {
    csv: true,
    pdf: false,
  },
}

type Props = {
  columns: any[];
  data: any[];
  detailPanel: any;
}

const MaterialTableComponent: React.FC<Props> = ({ columns, data, detailPanel }) => {
  return (
    <MaterialTable
      title=""
      columns={columns}
      data={data}
      localization={localization}
      detailPanel={detailPanel}
      options={options}
      icons={tableIcons}
    />
  )
}

export default MaterialTableComponent;