import { Fragment, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
// components
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import LinearProgress from '@material-ui/core/LinearProgress';
import MaterialTableComponent from '../../MaterialTable/MaterialTableComponent';
import SiteModal from './SiteModal';
// icons.
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { deleteSite, getSites, SiteI } from '../../../services/sitesService';
import NavBarComponent from '../../UI/NavBarComponent';

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    minHeight: '80vh'
  },
  card: {
    width: '100%',
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
  },
}));

const SitesMainScreen = () => {
  const { card, content, appBarSpacer } = useStyles();
  const [loading, setloading] = useState<boolean>(false)
  const [currentSite, setCurrentSite] = useState<SiteI>({ SITE_ID:'', SITE_NAME:'', SITE_URL:'' });
  const [editMode, setEditMode] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false)
  const [sites, setSites] = useState<SiteI[]>([]);

  const handleAddSite = () => {
    setOpen(true);
    setEditMode(false);
  }

  const handleUpdateSite = (site: SiteI) => {
    setCurrentSite(site);
    setEditMode(true);
    setOpen(true);
  }

  const addSiteToCurrentList = (site: SiteI) => {
    return setSites([ ...sites, site ]);
  }

  const updateSiteOnCurrentList = (site: SiteI) => {
    const sitesList = sites.map(s => s.SITE_ID === site.SITE_ID ? site : s);
    return setSites(sitesList);
  }

  const removeSiteOnCurrentList = (id: string) => {
    const sitesList = sites.filter(s => s.SITE_ID !== id);
    return setSites(sitesList);
  }

  const handleDeleteSite = (site: SiteI) => {
    Swal.fire({
      text: `Está seguro de eliminar el sitio ${site.SITE_URL}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then(async result => {
      if (result.value) {
        const data = await deleteSite(site.SITE_ID);
        if(data.ok){
          removeSiteOnCurrentList(data.siteId);
          return Swal.fire('', data.message, 'success');
        }else{
          return Swal.fire('', data.error.message, 'success');
        }
      }
    });
  }

  useEffect(() => {
    setloading(true);
    getSites()
      .then(resp => setSites(resp.sites))
      .finally(() => setloading(false));
  }, [])

  return (
    <Fragment>
      <NavBarComponent/>
      <main className={content}>
        <div className={appBarSpacer} />
        <Card className={card}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddSite}
                >
                  Añadir sitio
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                { loading && <LinearProgress />}
                <MaterialTableComponent
                  columns={[
                    { title: 'Sitio', field: 'SITE_NAME' },
                    { title: 'URL', field: 'SITE_URL' },
                  ]}
                  data={sites}
                  detailPanel={(row: SiteI) => (
                    <Container maxWidth="lg" style={{ padding: '20px' }}>
                      <Grid container spacing={3}>
                        <Grid item sm={3} xs={12}>
                          <Button
                            fullWidth
                            color="primary"
                            startIcon={<EditIcon />}
                            onClick={() => handleUpdateSite(row)}
                          >
                            Actualizar
                          </Button>
                        </Grid>
                        <Grid item sm={3} xs={12}>
                          <Button
                            fullWidth
                            color="primary"
                            startIcon={<DeleteIcon />}
                            onClick={() => handleDeleteSite(row)}
                          >
                            Eliminar
                          </Button>
                        </Grid>
                      </Grid>
                    </Container>
                  )}
                />
              </Grid>
            </Grid>

            <SiteModal
              site={currentSite}
              open={open}
              setOpen={setOpen}
              addSiteToList={addSiteToCurrentList}
              editSiteToList={updateSiteOnCurrentList}
              editMode={editMode}
            />
          </CardContent>
        </Card>
      </main>
    </Fragment>
  )
}

export default SitesMainScreen
