import axios from "axios";
import { getCookieByName } from "./helpers/getCookieByName";

axios.interceptors.request.use(
    function (config) {
        const pathName = window.location.pathname;
        if(pathName.includes('reset-password')){
            const currentToken = getCookieByName('reset_token');
            config.headers = {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                Authorization: "Bearer " + currentToken,
            };

            return config;
        }

        const currentToken = getCookieByName('token');
        config.headers = {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
            Authorization: "Bearer " + currentToken,
        };

        return config;
    },

    function (error) {
        return Promise.reject(error);
    }
);

// Add a response interceptor
axios.interceptors.response.use(
    function (response) {
        return response;
    },

    function (error) {
        const code = error.response.status;
        if (code === 401 || code === 500) {
            localStorage.clear();

            const pathname = window.location.pathname;
            if(pathname !== "/login" && !pathname.includes('reset-password')){
                window.location.href = "/login";
            }
        }

        return Promise.reject(error);
    }
);
