import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { useEffect } from 'react';
import { expireCookie } from '../../../helpers/expireCookie';

const LogoutScreen: React.FC<{}> = () => {
  const history = useHistory();
  const locationUrl = useLocation();
  const { site = '' } = queryString.parse(locationUrl.search);

  useEffect(() => {
    expireCookie('token', '/');
    expireCookie('rol', '/');
    history.replace(`/login?site=${site}`);
  }, [ history, site ])

  return (
    <div style={{
      display: 'grid',
      justifyContent: 'center',
      alignContent: 'center',
      minHeight: '95vh',
    }}>
      <CircularProgress />
    </div>
  )
}

export default LogoutScreen;
