import { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
// formularios.
import { Formik } from "formik";
import * as yup from 'yup';
// components.
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';

import { useLoginStyles } from './makeStyless/useLoginStyles';
import { sendPasswordToUpdate } from '../../../services/usersService';
import { validateToken } from '../../../services/usersService';
import Swal from 'sweetalert2';
import { useCookies } from 'react-cookie';
import { cookiesDomain } from '../../../config/config';

type UrlParamsTypes = {
  token: string;
};

const ResetPasswordScreen: React.FC = () => {
  const [cookie, setCookie] = useCookies(['reset_token']);
  const classes = useLoginStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { token } = useParams<UrlParamsTypes>();
  setCookie('reset_token', token, { domain: cookiesDomain });

  const formValues = {
    password: "",
  };

  const validationSchema = yup.object({
    password: yup
      .string()
      .min(4, "Se requiren 4 caracteres minimo")
      .max(12, "Se requiren menos de 12 caracteres")
      .required("Contraseña requerida."),
  });

  useEffect(() => {
    localStorage.setItem('token', token);
    validateToken();
    return () => {
      localStorage.clear();
    }
  }, [token]);

  return (
    <Container component="main" maxWidth="xs" className={classes.main}>
      <CssBaseline />
      <Card>
        { isLoading && <LinearProgress />}
        <CardContent>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOpenIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Actualización de contraseña.
            </Typography>
            <Formik
              initialValues={formValues}
              validationSchema={validationSchema}
              onSubmit={async (values) => {
                setIsLoading(true);
                const data = await sendPasswordToUpdate(values.password);
                if(data.ok){
                  setIsLoading(false);
                  Swal.fire('', data.message, 'success');
                  history.push('/login');
                }else{
                  setIsLoading(false);
                  Swal.fire('', data.error.message, 'error');
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                handleBlur,
              }) => (
                <form onSubmit={handleSubmit}>
                  <TextField
                    required
                    fullWidth
                    margin="normal"
                    name="password"
                    label="Nueva contraseña"
                    type="password"
                    variant="outlined"
                    autoComplete="current-password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.password && Boolean(errors.password)
                    }
                    helperText={touched.password && errors.password}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Actualizar contraseña
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Link to="/login" style={{ textDecoration: 'none' }} >Iniciar sesión.</Link>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </div>
        </CardContent>
      </Card>
    </Container>
  );
};

export default ResetPasswordScreen;
